@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap);
html,
body,
#root {
    margin: 0;
    height: 100%;
    width: 100%;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Red Hat Display';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/* override for calendar datepicker css --> calendar is added outside of any component */

.flatpickr-calendar.open:focus {
    outline: none;
}


/* prevents scrollbar on certain scale */

.flatpickr-calendar {
    right: 0 !important;
}

.VisualizationTooltip {
    font-family: "Red Hat Display", Arial, Helvetica, sans-serif;
    font-size: calc(8px + .4vmax);
    color: rgb(100, 106, 121);
    padding: 5px;
}

.highcharts-tooltip-container {
    z-index: 99999999 !important;
}

.VisualizationTooltip td {
    padding: 5px;
}

:root {
    --main-gray: #F8F8F8;
    --main-gray-highlight: #ebebeb;
    --secondary-gray: #DFDFDF;
    --main-blue: #2A5B8A;
    --main-green: #00A643;
    --title-text: #999999;
    --secondary-text: #666666;
    --accent-blue: #0078d4;
    --warning-red: #c42d35;
    --warning-red-bg: #FFF0F1;
    --status-green-bg: #DAFBE4;
    --status-neutral: #EFBF15;
    --status-neutral-bg: #FFF8E1;
    --purple-header: #3F4177;
    --dark-header: #070707;
}
